/* ===============================
=            CONTACT            =
=============================== */
::placeholder {
  color: #c8a4ff; }

::-webkit-input-placeholder {
  color: #c8a4ff; }

::-moz-placeholder {
  color: #c8a4ff; }

:-ms-input-placeholder {
  color: #c8a4ff; }

#Contact {
  background: #7d41ff;
  background-image: -webkit-linear-gradient(35deg, #00009a, #b300b3);
  background-image: linear-gradient(35deg, #00009a, #b300b3);
  padding: 350px 0 500px; }
  @media only screen and (max-width: 500px) {
    #Contact {
      padding: 175px 0 250px; } }

#Contact:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 255, 44, 0.67);
  -webkit-transition: all 225ms ease;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease;
  opacity: 0; }

#Contact.success:before {
  opacity: 1; }

#Contact .contact-form {
  text-align: right;
  position: relative;
  z-index: 1; }

#Contact canvas {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 0; }

.hide-those-particles #Contact canvas {
  display: none !important; }

#Contact .input-wrap {
  position: relative;
  height: 60px;
  margin-bottom: 10px; }

#Contact .input-wrap.textarea {
  height: 300px; }

#Contact .select-wrap {
  position: relative;
  z-index: 999999; }

#Contact .input-wrap input,
#Contact .input-wrap select {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  position: absolute;
  left: 3%;
  width: 94%;
  height: 53px;
  z-index: 9999;
  margin-top: 3px;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.03em;
  color: #fff;
  background: #401470;
  z-index: 9999999; }

#Contact .input-wrap textarea {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 3%;
  width: 94%;
  display: block;
  padding: 7px;
  height: 300px;
  font-weight: 300;
  background: #401470;
  -webkit-transition: all 225ms ease;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.06em;
  line-height: 29px;
  resize: none;
  z-index: 9999; }

#Contact .input-wrap svg {
  position: absolute;
  top: -9px;
  left: 0;
  height: 78px; }

#Contact .input-wrap.textarea svg {
  height: 410px;
  top: -54px; }

#Contact .input-wrap.checkbox {
  margin-top: 35px; }

#Contact .input-wrap.checkbox input {
  width: 35px;
  height: 35px;
  display: block;
  position: absolute;
  right: 0;
  left: auto;
  opacity: 0; }

#Contact .input-wrap.checkbox svg {
  width: 45px;
  height: 55px;
  left: auto;
  right: 19px; }

#Contact .input-wrap.checkbox label {
  padding-right: 53px;
  color: #fff;
  font-size: 23px;
  font-weight: 300;
  margin-top: 2px;
  z-index: 9999999;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  margin-right: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

#Contact .input-wrap.checkbox i {
  color: #b4ffb4;
  font-size: 30px;
  z-index: 99999;
  position: absolute;
  right: 26px;
  top: 3px;
  -webkit-transition: all 225ms ease;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease;
  display: block;
  opacity: 1;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0); }

#Contact .input-wrap.checkbox i.ti-control-stop {
  opacity: 0;
  font-size: 32px; }

#Contact .input-wrap svg path {
  fill: #401470;
  -webkit-transition: all 225ms ease;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease; }

#Contact .input-wrap.checkbox.nopeee svg path {
  fill: #e3e3e3; }

#Contact .input-wrap.checkbox.nopeee i {
  color: #ff787e; }

#Contact .input-wrap.checkbox.nopeee i.ti-control-stop {
  opacity: 1;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

#Contact .input-wrap.checkbox.nopeee i.ti-check-box {
  opacity: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

#Contact h2 {
  margin: 0;
  color: #fff;
  line-height: 135px;
  text-align: right;
  z-index: 10;
  position: relative; }

#Contact h2 span {
  display: inline-block; }

#Contact h2 span.lets {
  font-weight: 200;
  font-size: 175px;
  margin-top: -1px; }
  @media only screen and (max-width: 500px) {
    #Contact h2 span.lets {
      font-size: 100px; } }

#Contact h2 span.chat {
  font-size: 220px; }
  @media only screen and (max-width: 500px) {
    #Contact h2 span.chat {
      font-size: 120px; } }

#Contact p.lead {
  margin: 0;
  color: #e9d6ff;
  text-align: right;
  font-size: 23px;
  font-family: 'Open Sans', sans-serif;
  line-height: 37px;
  letter-spacing: 0.02em;
  margin-top: 51px;
  z-index: 10;
  position: relative; }
  @media only screen and (max-width: 500px) {
    #Contact p.lead {
      font-size: 20px;
      text-align: center; } }

#Contact p.lead a {
  color: #fff; }

#Contact p.lead a:hover {
  color: #a2ffb0; }

#Contact .input-wrap.submit input {
  background: #ce88ff;
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0.05em;
  -webkit-transition: all 225ms ease;
  -moz-transition: all 225ms ease;
  transition: all 225ms ease; }

#Contact .input-wrap.submit svg path {
  fill: #ce88ff; }

#Contact .input-wrap.submit input:hover {
  background: #a2ffb0;
  color: #000;
  cursor: pointer; }

#Contact .input-wrap.submit input:hover ~ span svg path {
  fill: #a2ffb0; }

.form-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #000;
  z-index: -100;
  opacity: 0;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease; }

.show-form-overlay .form-overlay {
  z-index: 9999999999;
  opacity: 0.9; }

#Contact .input-wrap.has-error input,
#Contact .input-wrap.has-error textarea {
  background: #ff3c3c; }

#Contact .input-wrap.has-error svg path {
  fill: #ff3c3c; }

body {
  background: #000; }

.justify-content-center {
  transition: all 0.5s ease-in-out;
  background: #00001d;
  padding: 10px 0px; }
  @media only screen and (max-width: 500px) {
    .justify-content-center {
      padding: 30px 0px 10px 0px;
      margin-top: 10px; } }
  .justify-content-center .nav-link {
    transition: all 0.5s ease-in-out;
    font-size: 1.5rem;
    text-decoration: none;
    color: lightgrey !important;
    font-family: "Rajdhani", sans-serif; }
    .justify-content-center .nav-link:hover {
      cursor: pointer;
      color: white !important; }
    @media only screen and (max-width: 500px) {
      .justify-content-center .nav-link {
        font-size: 1rem; } }
  .justify-content-center .active {
    transition: all 0.5s ease-in-out;
    color: white !important;
    font-weight: 700;
    border-bottom: 2px solid white; }

.pink {
  background: #ff3535; }
  .pink .nav-link {
    text-decoration: none;
    color: black !important;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700; }
  .pink .active {
    color: white !important;
    font-weight: 700; }

.white {
  background: #fff; }
  .white .nav-link {
    text-decoration: none;
    color: black !important;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700; }
    .white .nav-link:hover {
      color: #ff3535 !important; }
  .white .active {
    color: #ff3535 !important;
    font-weight: 700 !important;
    border-bottom: 2px solid #ff3535; }

#particles {
  width: 100%;
  position: fixed; }
  @media only screen and (max-width: 500px) {
    #particles {
      width: 100%;
      height: 100vh !important;
      position: fixed; } }

#Cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative; }
  @media only screen and (max-width: 500px) {
    #Cover img {
      width: 320px; } }
  #Cover h1 {
    font-family: "Major Mono Display", monospace;
    font-size: 7rem;
    margin-bottom: 0;
    color: white;
    text-transform: uppercase;
    -webkit-animation: flickerAnimation 0.5s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite; }
    @media only screen and (max-width: 500px) {
      #Cover h1 {
        font-size: 3rem; } }
  #Cover h2 {
    font-family: "Wallpoet", cursive;
    font-size: 5rem;
    margin-top: 0;
    color: #ff3535; }
    @media only screen and (max-width: 500px) {
      #Cover h2 {
        font-size: 2rem; } }
  #Cover section {
    position: relative; }
  #Cover section::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 80%; }
  #Cover #section05 {
    padding-top: 70px;
    color: white;
    font-family: "Rajdhani", sans-serif;
    font-size: 2rem; }
  #Cover #section05 span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
    box-sizing: border-box; }

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0; } }

@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0; } }

@keyframes flickerAnimation {
  0% {
    text-transform: uppercase; }
  98% {
    text-transform: lowercase; }
  100% {
    text-transform: uppercase; } }

@-o-keyframes flickerAnimation {
  0% {
    text-transform: uppercase; }
  98% {
    text-transform: lowercase; }
  100% {
    text-transform: uppercase; } }

@-moz-keyframes flickerAnimation {
  0% {
    text-transform: uppercase; }
  98% {
    text-transform: lowercase; }
  100% {
    text-transform: uppercase; } }

@-webkit-keyframes flickerAnimation {
  0% {
    text-transform: uppercase; }
  98% {
    text-transform: lowercase; }
  100% {
    text-transform: uppercase; } }

#WhatWeDo {
  /*=============================
=            BLURB            =
=============================*/
  /* ===================================
=            OUR PROCESS            =
=================================== */ }
  #WhatWeDo #blurb {
    padding: 150px 0 300px;
    background: #00001d; }
    #WhatWeDo #blurb:before {
      content: ' ';
      display: block;
      height: 180px;
      width: 100%;
      background: #00001d;
      -webkit-transform: skewY(-3deg) translateZ(0);
      -moz-transform: skewY(-3deg) translateZ(0);
      -ms-transform: skewY(-3deg) translateZ(0);
      -o-transform: skewY(-3deg) translateZ(0);
      transform: skewY(-3deg) translateZ(0);
      position: absolute;
      left: 0;
      bottom: -110px;
      z-index: 1;
      overflow: hidden; }
    #WhatWeDo #blurb .contents {
      position: relative;
      z-index: 8; }
      #WhatWeDo #blurb .contents h2 {
        color: #fff;
        text-align: left;
        font-size: 100px; }
        #WhatWeDo #blurb .contents h2 span {
          display: block;
          line-height: 77%; }
        #WhatWeDo #blurb .contents h2 .line-1 {
          font-weight: 200;
          font-size: 100px;
          line-height: 100%;
          font-family: "Rajdhani", sans-serif; }
          @media only screen and (max-width: 500px) {
            #WhatWeDo #blurb .contents h2 .line-1 {
              font-size: 40px; } }
        #WhatWeDo #blurb .contents h2 .line-2 {
          font-weight: 300;
          font-size: 120px;
          font-family: "Rajdhani", sans-serif; }
          @media only screen and (max-width: 500px) {
            #WhatWeDo #blurb .contents h2 .line-2 {
              font-size: 50px; } }
        #WhatWeDo #blurb .contents h2 .line-3 {
          font-weight: 700;
          font-size: 150px;
          margin-top: 20px;
          font-family: "Rajdhani", sans-serif; }
          @media only screen and (max-width: 500px) {
            #WhatWeDo #blurb .contents h2 .line-3 {
              font-size: 55px; } }
      #WhatWeDo #blurb .contents hr {
        border: none;
        height: 1px;
        width: 300px;
        margin: 30px 0 25px;
        background: #ff3535;
        /* Old browsers */
        background: -moz-linear-gradient(left, #ff3535 0%, #ffb22d 19%, #f7df00 37%, #ff3535 56%, #ffb22d 76%, #f7df00 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #ff3535), color-stop(19%, #ffb22d), color-stop(37%, #f7df00), color-stop(56%, #ff3535), color-stop(76%, #ffb22d), color-stop(100%, #f7df00));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, #ff3535 0%, #ffb22d 19%, #f7df00 37%, #ff3535 56%, #ffb22d 76%, #f7df00 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, #ff3535 0%, #ffb22d 19%, #f7df00 37%, #ff3535 56%, #ffb22d 76%, #f7df00 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, #ff3535 0%, #ffb22d 19%, #f7df00 37%, #ff3535 56%, #ffb22d 76%, #f7df00 100%);
        /* IE10+ */
        background: linear-gradient(to right, #ff3535 0%, #ffb22d 19%, #f7df00 37%, #ff3535 56%, #ffb22d 76%, #f7df00 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3535', endColorstr='#f7df00', GradientType=1);
        /* IE6-9 */ }
      #WhatWeDo #blurb .contents p {
        font-size: 30px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        padding-right: 15%;
        line-height: 43px;
        color: #d6d8ff;
        margin: 0; }
        @media only screen and (max-width: 500px) {
          #WhatWeDo #blurb .contents p {
            font-size: 20px; } }
        #WhatWeDo #blurb .contents p strong {
          font-weight: 400; }
      #WhatWeDo #blurb .contents .item {
        transition: all 0.5s ease-in;
        opacity: 0;
        transform: translateX(-200px); }
    #WhatWeDo #blurb .items-appear {
      opacity: 1 !important;
      transform: translateX(0px) !important; }
  #WhatWeDo #our-process {
    text-align: center;
    background: #ff3535;
    padding-bottom: 250px; }
    @media only screen and (max-width: 500px) {
      #WhatWeDo #our-process {
        padding-bottom: 350px; } }
    #WhatWeDo #our-process:before {
      content: ' ';
      display: block;
      height: 100px;
      width: 100%;
      background: #ff3535;
      -webkit-transform: skewY(3deg) translateZ(0);
      -moz-transform: skewY(3deg) translateZ(0);
      -ms-transform: skewY(3deg) translateZ(0);
      -o-transform: skewY(3deg) translateZ(0);
      transform: skewY(3deg) translateZ(0);
      position: relative;
      left: 0;
      bottom: 50px;
      overflow: hidden;
      z-index: 88; }
  #WhatWeDo #our-process .huge-town {
    color: #ff1c1c;
    position: absolute;
    right: 0;
    font-family: "Rajdhani", sans-serif;
    text-align: right;
    font-size: 520px;
    line-height: 73%;
    font-weight: 700;
    opacity: 0.5;
    z-index: 89; }
    @media only screen and (max-width: 500px) {
      #WhatWeDo #our-process .huge-town {
        font-size: 110px;
        text-align: left;
        left: 0; } }
  #WhatWeDo #our-process .huge-town span {
    display: block;
    letter-spacing: -0.04em; }
  #WhatWeDo #our-process .container-fluid .row {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  #WhatWeDo #our-process .contents {
    position: relative;
    height: 800px;
    z-index: 90; }
  #WhatWeDo #our-process .contents .wrap {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 77777777; }
  #WhatWeDo #our-process .step {
    opacity: 1; }
  #WhatWeDo #our-process span.icon {
    display: block;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    line-height: 150px;
    color: #00d2c1;
    font-size: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: relative;
    border: solid 3px #fff; }
    @media only screen and (max-width: 500px) {
      #WhatWeDo #our-process span.icon {
        width: 75px;
        height: 75px;
        line-height: 75px;
        color: #00d2c1;
        font-size: 25px;
        margin-bottom: 20px; } }
  #WhatWeDo #our-process span.icon.think {
    color: #ff6e6e; }
  #WhatWeDo #our-process span.icon.design {
    color: #ffb446; }
  #WhatWeDo #our-process span.icon.engineer {
    color: #003267; }
  #WhatWeDo #our-process span.icon.execute {
    color: #5200c9; }
  #WhatWeDo #our-process span.icon:before {
    display: block;
    content: ' ';
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    opacity: 1;
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    transition: all 250ms ease;
    background: #fff; }
    @media only screen and (max-width: 500px) {
      #WhatWeDo #our-process span.icon:before {
        width: 75px;
        height: 75px; } }
  #WhatWeDo #our-process span.icon:hover,
  #WhatWeDo #our-process span.icon.active {
    color: #fff;
    background: transparent; }
  #WhatWeDo #our-process span.icon:hover:before,
  #WhatWeDo #our-process span.icon.active:before {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.25);
    -moz-transform: translate(-50%, -50%) scale(1.25);
    -ms-transform: translate(-50%, -50%) scale(1.25);
    -o-transform: translate(-50%, -50%) scale(1.25);
    transform: translate(-50%, -50%) scale(1.25); }
  #WhatWeDo #our-process span.icon i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 88;
    -webkit-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    transition: all 600ms ease; }
  #WhatWeDo #our-process h3 {
    font-size: 40px;
    color: #fff;
    letter-spacing: 0.05em; }
    @media only screen and (max-width: 500px) {
      #WhatWeDo #our-process h3 {
        font-size: 25px; } }
  #WhatWeDo #our-process h4 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(200px);
    -moz-transform: translateY(200px);
    -ms-transform: translateY(200px);
    -o-transform: translateY(200px);
    transform: translateY(200px);
    opacity: 0;
    font-size: 50px;
    color: #fff;
    font-weight: 200;
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    transition: all 250ms ease; }
    @media only screen and (max-width: 500px) {
      #WhatWeDo #our-process h4 {
        font-size: 25px; } }
  #WhatWeDo #our-process h4.active {
    opacity: 1; }
  #WhatWeDo #our-process h4 i {
    font-size: 9px;
    color: #ff9292;
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -m-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
    display: inline-block; }
  #WhatWeDo #our-process h4 span {
    margin: 0 5px; }

#WhoWeAre {
  /* ====================================
=            OUR SERVICES            =
==================================== */ }
  #WhoWeAre #Bio {
    background: white;
    position: relative;
    padding: 200px 0px 200px 0px; }
    @media only screen and (max-width: 500px) {
      #WhoWeAre #Bio {
        padding: 150px 0px 150px 0px; } }
    #WhoWeAre #Bio:before {
      content: ' ';
      display: block;
      height: 180px;
      width: 100%;
      background: #fff;
      -webkit-transform: skewY(-3deg) translateZ(0);
      -moz-transform: skewY(-3deg) translateZ(0);
      -ms-transform: skewY(-3deg) translateZ(0);
      -o-transform: skewY(-3deg) translateZ(0);
      transform: skewY(-3deg) translateZ(0);
      position: absolute;
      left: 0;
      top: -110px;
      z-index: 1;
      overflow: hidden; }
    #WhoWeAre #Bio .container {
      text-align: center; }
      #WhoWeAre #Bio .container h1 {
        font-family: "Rajdhani", sans-serif;
        font-weight: bold;
        font-size: 5rem;
        margin-bottom: 25px; }
        @media only screen and (max-width: 500px) {
          #WhoWeAre #Bio .container h1 {
            font-size: 3rem; } }
      #WhoWeAre #Bio .container img {
        border-radius: 50%;
        width: 320px;
        border: 2px solid green;
        margin-bottom: 25px; }
        @media only screen and (max-width: 500px) {
          #WhoWeAre #Bio .container img {
            width: 300px; } }
      #WhoWeAre #Bio .container p {
        font-size: 1.5rem;
        font-family: "Open Sans", sans-serif; }
        @media only screen and (max-width: 500px) {
          #WhoWeAre #Bio .container p {
            font-size: 1.2rem;
            padding: 10px; } }
        #WhoWeAre #Bio .container p .david {
          font-size: 2rem;
          font-weight: bold; }
        #WhoWeAre #Bio .container p .green {
          color: green;
          font-weight: bold; }
  #WhoWeAre #our-services {
    height: 100vh;
    background-image: url("../img/cover.jpg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    #WhoWeAre #our-services:before {
      background-image: -webkit-linear-gradient(125deg, #003267 7%, #ff0000 90%);
      background-image: linear-gradient(325deg, #003267 7%, #ff0000 90%);
      opacity: 0.475;
      content: ' ';
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1; }
    #WhoWeAre #our-services .container-fluid .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      @media only screen and (max-width: 500px) {
        #WhoWeAre #our-services .container-fluid .row {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
    #WhoWeAre #our-services .contents {
      z-index: 9999999;
      position: relative; }
    #WhoWeAre #our-services h2 {
      position: absolute;
      color: #fff;
      font-size: 235px;
      font-weight: 200;
      margin: 0;
      text-align: left;
      line-height: 190px;
      margin-top: -8px;
      font-family: "Rajdhani", sans-serif;
      text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1); }
      @media only screen and (max-width: 500px) {
        #WhoWeAre #our-services h2 .our {
          display: none; } }
      @media only screen and (max-width: 500px) {
        #WhoWeAre #our-services h2 {
          font-size: 50px;
          writing-mode: vertical-lr;
          text-orientation: upright;
          letter-spacing: -20px;
          line-height: 50px; } }
    #WhoWeAre #our-services .list ul {
      right: 0;
      font-family: 'Open Sans', sans-serif;
      position: absolute;
      list-style: none;
      margin: 0;
      padding: 0;
      color: #fff;
      font-size: 23px;
      font-weight: 400;
      text-align: right;
      line-height: 30px;
      margin-right: 20px;
      text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1); }

::placeholder {
  color: #c8a4ff; }

::-webkit-input-placeholder {
  color: #c8a4ff; }

::-moz-placeholder {
  color: #c8a4ff; }

:-ms-input-placeholder {
  color: #c8a4ff; }

#Footer {
  position: relative;
  background: #00001d;
  height: 400px; }
  #Footer:before {
    content: ' ';
    display: block;
    height: 180px;
    width: 100%;
    background: #00001d;
    -webkit-transform: skewY(-3deg) translateZ(0);
    -moz-transform: skewY(-3deg) translateZ(0);
    -ms-transform: skewY(-3deg) translateZ(0);
    -o-transform: skewY(-3deg) translateZ(0);
    transform: skewY(-3deg) translateZ(0);
    position: absolute;
    left: 0;
    top: -55px;
    z-index: 1; }
  #Footer .container {
    height: 100%;
    font-family: "Rajdhani", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }
    #Footer .container h1 {
      color: white;
      z-index: 9999; }
